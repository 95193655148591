
import React from "react";
import {useLaravelReactI18n} from "laravel-react-i18n";
import {Button, Container} from "react-bootstrap";

export default function Export(props) {
    const { t } = useLaravelReactI18n();

  const handleClick = () => {
    window.location.href ='export/download';
  }

  return (<Container fluid className={"p-3"}>
      <Button onClick={handleClick}>Produkte als CSV Exportieren</Button>
  </Container>);
}




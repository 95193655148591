import React, { useState, useEffect } from 'react';
import { usePage, router } from '@inertiajs/react';
import { Button } from 'react-bootstrap';
import {mdiPause, mdiPlay, mdiRestart} from "@mdi/js";
import Icon from '@mdi/react';
import {useLaravelReactI18n} from "laravel-react-i18n";

function HeaderNavExtraComponent(props) {
    const { runningState } = usePage().props;
    const [remain, setRemain] = useState(runningState.remain); // Initialize with runningState.remain
    const { t,getLocales } = useLaravelReactI18n();

    useEffect(() => {
        // Update remain state whenever runningState.remain changes
        setRemain(runningState.remain);
    }, [runningState.remain]);

    useEffect(() => {
        if (runningState.break && remain <= 0){
            router.reload();
            return; // Stop if the countdown reaches zero
        }

        const interval = setInterval(() => {
            setRemain(prevRemain => {
                if (prevRemain <= 0) {
                    clearInterval(interval); // Clear interval when countdown reaches zero

                    return 0;
                }
                return prevRemain - 1;
            });
        }, 1000); // 1000 ms = 1 second

        return () => clearInterval(interval); // Clean up interval on component unmount

    }, [remain]);

    const handlePauseClick = () => {
        router.get('break');
    }

    const handleRestartClick = () => {
        router.get('break/restart');
    }

    const remainText = () => {

        if (remain < 60) {
            return t(':remain seconds',{'remain':remain});
        } else {
            const minutes = Math.floor(remain / 60);
            return t(':remain minutes',{'remain':minutes});
        }
    }

    return (
        <>
            <Button className="me-2" variant="danger" title={runningState.break === true ? t('start background tasks'):t('stop background tasks')} onClick={handlePauseClick}>
                {runningState.break === true ? <Icon path={mdiPlay} size={1} /> : <Icon path={mdiPause} size={1} />}
            </Button>
            {runningState.break === true && (
                <>
                    <Button variant="primary" title={t('restart break')} onClick={handleRestartClick}>
                        <Icon path={mdiRestart} size={1} />
                    </Button>
                    <div className="d-flex align-items-center">
                        <p className="mb-0 ms-2">{t('remain')}: {remainText()}</p>
                    </div>
                </>
            )}
        </>
    );
}

export default HeaderNavExtraComponent;

import React, {useRef, useState} from "react";
import {useLaravelReactI18n} from "laravel-react-i18n";
import {usePage, router} from "@inertiajs/react";
import {Container, Card, Form, ToggleButton, Button, ToggleButtonGroup} from "react-bootstrap";
import ImportOptionsCsv from "./Import/ImportOptionsCsv.jsx";
import InertiaTable from "laravel-inertia-react-table";
import ImportOptions from "./Import/ImportOptions.jsx";
import ImportSelect from "./Import/ImportSelect.jsx";
import Icon from '@mdi/react';
import {mdiCheckboxMultipleOutline, mdiFilterOutline} from "@mdi/js";

export default function Import(props) {
    const {t, getLocales} = useLaravelReactI18n();
    const {importSource, importReady, importId, importItems, initialSelectedItems} = usePage().props;
    const [tableOptions,setTableOptions] = useState({selectable: false, showCheckbox:true, updateOnly: ['importItems']});
    const [selectedItems, _setSelectedItems] = useState([]);
    const [importFilter, setImportFilter] = useState('all');
    const [selectedAttributes, setSelectedAttributes] = useState([]);
    const selectedItemsRef = useRef(selectedItems); // Workarround für bug in Inertia
    // Aktualisieren Sie die ref jedes Mal, wenn setSelectedItems aufgerufen wird
    const setSelectedItems = (data) => {
        if (typeof data === 'function') {
            _setSelectedItems(prevState => {
                const updatedState = data(prevState);
                selectedItemsRef.current = updatedState;
                return updatedState;
            });
        } else {
            selectedItemsRef.current = data;
            _setSelectedItems(data);
        }
    };

    const handleImportFilterChange = (val) => {

        router.reload({
            only: ['import_filter', 'importItems'],
            data: {import_filter: val},
        });
        setImportFilter(val);
    }
    const customToolbarButtons = (<>
        <ToggleButtonGroup type="radio" name="import-filter" value={importFilter} onChange={handleImportFilterChange}>
            <span className="input-group-text" id="mode"><Icon path={mdiFilterOutline} size={1} /></span>
            <ToggleButton id="tbg-only-new" variant="secondary" value="only-new">
                nur neue
            </ToggleButton>
            <ToggleButton id="tbg-all" variant="secondary" value="all">
                alle
            </ToggleButton>
            <ToggleButton id="tbg-update" variant="secondary" value="update">
                nur update
            </ToggleButton>
        </ToggleButtonGroup>
        <ToggleButton id="tgb-selectable" className={"ms-2"} type="checkbox"
                      variant="secondary"
                      checked={tableOptions.selectable}
                      value="1" onClick={() => {console.log("Click");  setTableOptions({...tableOptions,selectable: !(tableOptions.selectable)})}}> <Icon path={mdiCheckboxMultipleOutline} size={1} /></ToggleButton>
    </>);

    const handleAttributeChange = (event) => {
        const value = event.target.value;
        setSelectedAttributes(prevSelectedAttributes => {
            if (prevSelectedAttributes.includes(value)) {
                return prevSelectedAttributes.filter(attr => attr !== value);
            } else {
                return [...prevSelectedAttributes, value];
            }
        });
    };

    const handleStartImport = () => {
        router.post('import/run', {action: 'import', import_filter:importFilter, importId: importId, importAll:!(tableOptions.selectable),selectedItems: selectedItems,selectedAttributes: selectedAttributes});
    }

    let importOptions = null;
    switch (importSource) {
        case 'b2b':
        case 'b2c':
            importOptions = (<ImportOptions></ImportOptions>);
            break;
        case 'csv':
            importOptions = (<ImportOptionsCsv></ImportOptionsCsv>);
    }
    const handleOnChangeSource = (event) => {
        const value = event.target.value;
        router.reload({
            only: ['importSource'],
            data: {importSource: value},
        });
    }

    const ImportAttributeSelect = ({ selectedAttributes, onChangeAttribute }) => {
        return (
            <Form.Group>
                <Form.Label>{t('Select Import Attributes')}</Form.Label>
                <div>
                    {['title','display_title', 'sortorder', 'image', 'color_image', 'color_code'].map(attribute => (
                        <Form.Check
                            key={attribute}
                            inline
                            label={t(attribute)}
                            name="import-attribute"
                            type="checkbox"
                            id={`attribute-${attribute}`}
                            value={attribute}
                            checked={selectedAttributes.includes(attribute)}
                            onChange={onChangeAttribute}
                        />
                    ))}
                </div>
            </Form.Group>
        );
    };


    return (<>
            <div className="px-3 mt-5 d-flex">
                <h1>{t('Import')}</h1>
            </div>
            <Container fluid className={"p-3"}>
                <ImportSelect></ImportSelect>
                <Card className={'mb-3'}>
                    <Card.Header>
                        {t('Import Source')}
                    </Card.Header>
                    <Card.Body>
                        <Form.Check
                            inline
                            label="B2C"
                            name="import-source"
                            type="radio"
                            id="source-b2c"
                            value="b2c"
                            disabled={importItems !== undefined}
                            checked={importSource === 'b2c'}
                            onChange={handleOnChangeSource}
                        />
                        <Form.Check
                            inline
                            label="B2B"
                            name="import-source"
                            type="radio"
                            id="source-b2b"
                            value="b2b"
                            disabled={importItems !== undefined}
                            checked={importSource === 'b2b'}
                            onChange={handleOnChangeSource}
                        />
                        <Form.Check
                            inline
                            label="CSV"
                            name="import-source"
                            type="radio"
                            id="source-csv"
                            value="csv"
                            disabled={importItems !== undefined}
                            checked={importSource === 'csv'}
                            onChange={handleOnChangeSource}
                        />
                    </Card.Body>
                </Card>
                {importOptions ? <Card className={'mb-3'}>
                    <Card.Header>{t('Import Options')}</Card.Header>
                    <Card.Body>{importOptions}</Card.Body>
                </Card> : <></>}
                {importItems ? <Card className={'mb-3'}>
                    <Card.Header>SKUs</Card.Header>
                    <Card.Body>
                        <InertiaTable data={importItems} customToolbarButtons={customToolbarButtons}
                                      selectedIds={selectedItems} onSelectedIdsChange={setSelectedItems}
                                      options={tableOptions} />

                    </Card.Body>
                </Card> : <></>}
                {importItems && <Card>
                    <Card.Body className={'flex align-content-end'}>

                        <ImportAttributeSelect
                            selectedAttributes={selectedAttributes}
                            onChangeAttribute={handleAttributeChange}
                        />


                        <Button onClick={handleStartImport}>{t('start import')}</Button>
                    </Card.Body>
                </Card>}

            </Container>
        </>
    );
}

import React, { useState } from 'react'
import {usePage,router} from "@inertiajs/react";
import {Container, Row, Col, Button, Card, Modal, ListGroup} from 'react-bootstrap';
import { Icon } from '@mdi/react'
import {mdiCodeJson, mdiEmail, mdiEye, mdiFilePdfBox, mdiRefresh} from '@mdi/js'
import {useLaravelReactI18n} from "laravel-react-i18n";
import { JsonView, allExpanded, darkStyles, defaultStyles } from 'react-json-view-lite';
import 'react-json-view-lite/dist/index.css';

export default function Show() {
    const { t, getLocales } = useLaravelReactI18n();
    const { order } = usePage().props;
    const [values, setValues] = useState({
        first_name: order.customer_name,
        last_name: order.last_name,
        email: order.email,
    });
    const [modalVisible, setModalVisible] = useState(false);
    const [dumpModalVisible, setDumpModalVisible] = useState(false);
    const [previewResponse, setPreviewResponse] = useState(null);
    // Zustand für die HTML-Antwort
    const [htmlResponse, setHtmlResponse] = useState('');
    function handleChange(e) {
        const key = e.target.id;
        const value = e.target.value;
        setValues(values => ({
            ...values,
            [key]: value,
        }))
    }

    function handlePreview() {
        axios.get(`preview`,{ params: { id: order.id }})
            .then(response => {
                setHtmlResponse(response.data);
                setModalVisible(true);
            });
    }

    function handlePdf() {
        window.open(`download/`+ order.id,'_blank');
    }

    function handleDumpShow(){
        if (order.request_dump){
            setDumpModalVisible(true);
        }
    }

    function handleRecreate() {

        router.get(`recreate/${order.id}`);
    }
    function handleResendEmail() {
        if (window.confirm(t("Are you sure you want to resend order email?"))) {
            router.get(`resend/${order.id}`);
        }

    }

    function getConnectionType(id) {
        switch(id) {
            case 1: return 'B2B';
            case 2: return 'B2C alt';
            case 3: return 'B2C';
            default: return '';
        }
    }

    return (
        <Container>
            <Row>
                <Col>
                    <Card className={'mb-4'}>
                        <Card.Header>
                            <div className="d-flex justify-content-between">
                                <h4>{`${getConnectionType(order.connection_id)} ${t('order')} ${order.shop_order_id}`}</h4>
                                <div>

                                    {order.request_dump && <Button onClick={handleDumpShow} variant="link">
                                        <Icon path={mdiCodeJson} size={1}/>
                                    </Button>}
                                    <Button onClick={handlePdf} variant="link">
                                        <Icon path={mdiFilePdfBox} size={1}/>
                                    </Button>
                                    <Button onClick={handlePreview} variant="link">
                                        <Icon path={mdiEye} size={1}/>
                                    </Button>
                                    <Button onClick={handleRecreate} variant="link">
                                        <Icon path={mdiRefresh} size={1}/>
                                    </Button>
                                    <Button onClick={handleResendEmail} disabled={!order.status} variant="link">
                                        <Icon path={mdiEmail} size={1}/>
                                    </Button>
                                </div>
                            </div>
                        </Card.Header>
                        <Card.Body>
                        <Row>
                            <Col>
                                <h5>{t('order details')}</h5>
                                <p><strong>{t('order id')}:</strong> {order.shop_order_id}</p>
                                <p><strong>{t('date')}:</strong> {order.order_date}</p>
                                <p><strong>Kundenname:</strong> {order.customer_name}</p>
                                <p><strong>Status:</strong> <span className={order.status ? '' : 'text-danger'}>{order.status ? t('success') : t('error')}</span>
                                </p>
                                <p><strong>Gesendet:</strong> {order.send ? order.send : 'noch nicht gesendet'}</p>
                            </Col>
                        </Row>
                        </Card.Body>
                    </Card>
                    {!order.status && (
                        <Card>
                            <Card.Header><h5>{t('order errors')}</h5></Card.Header>
                                {order.order_errors && order.order_errors.length > 0 && (

                                    <ListGroup variant={"flush"}>
                                        {order.order_errors.map((error, index) => (
                                            <ListGroup.Item key={index}>
                                                <strong>SKU:</strong> {error.sku}, <strong>Description:</strong> {error.description}
                                            </ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                )}
                        </Card>
                    )}
                </Col>
            </Row>
            <Modal show={modalVisible} fullscreen={true} onHide={() => setModalVisible(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('ordermail preview')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <div dangerouslySetInnerHTML={{ __html: htmlResponse }} />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalVisible(false)}>
                        {t('Close')}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal show={dumpModalVisible} fullscreen={true} onHide={() => setDumpModalVisible(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('ordermail request dump')}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <JsonView data={order.request_dump} shouldExpandNode={allExpanded} style={darkStyles} />
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setDumpModalVisible(false)}>
                        {t('Close')}
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    )
}

import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import {router, usePage} from '@inertiajs/react';
import {useLaravelReactI18n} from "laravel-react-i18n";
import {ProgressBar} from 'react-bootstrap';

const Process = () => {
    const { t } = useLaravelReactI18n();
    const { remainingImportIds,state,importId,selectedAttributes,originalItemsCount } = usePage().props;
    const progress = ((originalItemsCount - remainingImportIds.length) / originalItemsCount) * 100;

    router.post('/import/run',{action: 'import', importId: importId, selectedItems: remainingImportIds,selectedAttributes: selectedAttributes,originalItemsCount:originalItemsCount},{hideProgress: true});

    if (state==='running'){
        return (
            <Container>
                <h5>Import in Bearbeitung</h5>
                <ProgressBar now={progress} label={`${Math.round(progress)}%`}/>
            </Container>
        )
    }
    return (
        <Container>
            Fertig
        </Container>
    );
};

export default Process;

import React, {useState} from "react";
import {router, usePage} from "@inertiajs/react";
import {useLaravelReactI18n} from "laravel-react-i18n";
import InertiaTable from "laravel-inertia-react-table";
import {Button, ButtonGroup, ToggleButton, ToggleButtonGroup} from "react-bootstrap";
import Icon from "@mdi/react";
import {mdiContentSave, mdiPlus, mdiTrashCan} from "@mdi/js";


export default function Products(props) {
    const {t, getLocales} = useLaravelReactI18n();
    const {products,sortfield,sortorderSplitmode} = usePage().props;
    const [sortMode, setSortMode] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const tableOptions = {selectable: false, showCheckbox: true,striped:true};

    const handleSortButtonClick = () => {
        setSortMode(!sortMode);
    }

    const handleRebalanceClick = () => {
        router.get('product/rebalance');
    }

    const handleSortorderSwitchChange = (value) =>{

        router.reload({data:{sortfield:value}});
    }
    const handleDeleteButtonClick = () => {

        if (window.confirm(t("Are you sure you want to :count products delete?",{'count':selectedItems.length}))) {
            router.post('product/delete', {ids: selectedItems});
            setSelectedItems([]);
        }
    }

    const handleAddButtonClick = () => {
         router.get('product/create');
    }

    const customToolbarButtons = (<>
        <ToggleButton type="checkbox"
                                                variant="secondary"
                                                checked={sortMode}
                                                value="1" onClick={handleSortButtonClick}>Sortieren</ToggleButton>
        <Button hidden={selectedItems.length===0} type="submit" className={'ms-2'} title={t('delete')} onClick={handleDeleteButtonClick}>
            <Icon path={mdiTrashCan} size={1}/>
        </Button>

        <Button  type="submit" className={'ms-2'} title={t('add')} onClick={handleAddButtonClick}>
            <Icon path={mdiPlus} size={1}/>
        </Button>

        <ToggleButtonGroup className={'ms-2'} name="sortorder_switch" value={sortfield} type="radio" onChange={handleSortorderSwitchChange}>
            <ToggleButton disabled={!sortorderSplitmode} id={"sortorder1"} variant="secondary" value={"sortorder"}>{t('Sort B2C')}</ToggleButton>
            <ToggleButton disabled={!sortorderSplitmode} id={"sortorder2"} variant="secondary" value={"sortorder2"}>{t('Sort B2B')}</ToggleButton>
        </ToggleButtonGroup>

        <Button type="submit" className={'ms-2'} variant="secondary" title={t('rebalance')} onClick={handleRebalanceClick}>
            {t('rebalance')}
        </Button>


    </>);


    const handleRowClick = (product) => {
        router.get('product/'+product.id+'/edit');
    }

    return (<InertiaTable title={t('Products')} sortMode={sortMode} customToolbarButtons={customToolbarButtons}
                          data={products}
                          options={tableOptions}
                          onSelectedIdsChange={setSelectedItems}
                          selectedIds={selectedItems}
                          onRowClick={handleRowClick}
    />);
}


import React from "react";
import {usePage} from "@inertiajs/react";
import {useLaravelReactI18n} from "laravel-react-i18n";
import InertiaTable from "laravel-inertia-react-table";

export default function ActivityLogs(props) {
    const { t } = useLaravelReactI18n();
    const { activitylogs } = usePage().props;

  return (<InertiaTable data={ activitylogs }/>);
}



